import React from 'react'
import {graphql} from 'gatsby'
import Img from 'gatsby-image'
import SEO from '../components/seo'
import Carousel from '../components/carousel'
import PortableText from '../components/portableText'
import FeatureBox from "../components/featureBox";
import Share from "../components/share";
import {getFullUrl, getPostUrl} from "../lib/helpers";
import Article from "../components/article";

export const query = graphql`
query ProductPageQuery($id: String) {
    product: sanityProduct(id: {eq: $id}) {
        title
        excerpt
        _rawBody
        slug {
            current 
        }
        functions {
            title
            features
        }
        images {
            ...Image
        }
        mobileImages: images {
            ...MobileImage
        }
    }
}
`

class ProductPage extends React.Component {
    constructor(props) {
        super(props)
        const {data, errors} = props
        this.state = {
            product: (data || {}).product,
        }
    }


    splitTitle(name) {
        if (name.includes(':')) {
            let fragments = name.split(':')
            return {
                product: fragments[0],
                name: fragments[1]
            }
        }
        return {
            product: null,
            name
        }
    }

    render() {
        return (
            <>
                <SEO
                    title={this.state.product.title}
                    image={this.state.product.images ? this.state.product.images[0] : null}
                />

                <Article
                    title={(
                        <div className='flex items-end'>
                            {this.splitTitle(this.state.product.title).product && (
                                <div
                                    className='mr-3 text-3xl mb-5px'>{this.splitTitle(this.state.product.title).product}:</div>
                            )}
                            <div>{this.splitTitle(this.state.product.title).name}</div>
                        </div>
                    )}
                    excerpt={this.state.product.excerpt}
                    byline={(
                        <div className='md:hidden -mx-6'>
                            <FeatureBox content={this.state.product.functions}/>
                        </div>
                    )}
                    image={(
                        <Carousel>
                            {this.state.product.images && this.state.product.images.map((image, index) => (
                                <div key={`carousel-image-${index}`}>
                                    <Img fluid={image.asset.fluid} className='-mb-2'/>
                                </div>
                            ))}
                        </Carousel>
                    )}
                    mobileImage={(
                        <Carousel>
                            {this.state.product.mobileImages && this.state.product.mobileImages.map((image, index) => (
                                <div key={`carousel-image-${index}`}>
                                    <Img fluid={image.asset.fluid} className='-mb-2'/>
                                </div>
                            ))}
                        </Carousel>
                    )}
                    sidebar={(
                        <div className='hidden md:block'>
                            <FeatureBox content={this.state.product.functions}/>
                        </div>
                    )}
                >
                    <div className="body-text">
                        {this.state.product._rawBody &&
                        <PortableText blocks={this.state.product._rawBody}/>}
                    </div>

                    <Share
                        url={getFullUrl(`/product/${this.state.product.slug.current}`)}
                        title={this.state.product.title}/>
                </Article>
            </>
        )
    }
}

export default ProductPage
